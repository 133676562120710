import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MainLayout from "../layout/MainLayout.vue";
import { getToken } from "@/utils/auth";
import store from "@/store";
import authEntity from "@/utils/authEntity";

const whiteList = ["/login", "/register", "/password-modify-sms"];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/user/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/enterprise/EnterpriseRegister.vue"
      ),
  },
  {
    name: "password-modify-sms",
    path: "/password-modify-sms",
    component: () => import("../views/user/PasswordModifyBySms.vue"),
  },
  {
    name: "preview",
    path: "/preview",
    component: () => import("../views/preview/index.vue"),
  },
];

const mainRouters = {
  path: "/",
  name: "main",
  component: MainLayout,
  redirect: "/enterprises",
  children: [
    {
      name: "enterprises",
      path: "enterprises",
      component: () => import("../views/enterprise/Enterprises.vue"),
      meta: {
        perms: authEntity.ENTERPRISE_LIST,
        type: "M",
        title: "企业管理",
        icon: "shop-outlined",
      },
    },
    {
      name: "enterprise-add",
      path: "enterprise-add",
      component: () => import("../views/enterprise/EnterpriseAdd.vue"),
      meta: {
        perms: authEntity.ENTERPRISE_ADD,
        type: "F",
        title: "新增企业",
      },
    },
    {
      name: "enterprise-detail",
      path: "enterprise-detail-:companyId",
      component: () => import("../views/enterprise/EnterpriseBaseInfo.vue"),
      meta: {
        perms: authEntity.ENTERPRISE_DETAIL,
        type: "F",
        title: "企业详情",
      },
    },
    {
      name: "enterprise-edit",
      path: "enterprise-edit-:enterpriseId",
      component: () => import("../views/enterprise/EnterpriseEdit.vue"),
      meta: {
        perms: authEntity.ENTERPRISE_EDIT,
        type: "F",
        title: "编辑企业",
      },
    },
    {
      name: "financial",
      path: "financial-:enterpriseId",
      component: () => import("../views/enterprise/FinancialSituation.vue"),
      meta: {
        perms: authEntity.ENTERPRISE_FINANCIAL,
        type: "F",
        title: "财务情况",
      },
    },
    {
      name: "enterprise-aduit",
      path: "enterprise-aduit",
      component: () => import("../views/enterprise/EnterpriseAudit.vue"),
      meta: {
        perms: authEntity.ENTERPRISE_AUDIT_LIST,
        type: "M",
        title: "企业审核",
        icon: "audit-outlined",
      },
    },
    {
      name: "follow-record",
      path: "follow-record-:companyId",
      component: () => import("../views/enterprise/FollowRecords.vue"),
      meta: {
        perms: authEntity.ENTERPRISE_PROGRESS,
        type: "F",
        title: "跟进记录",
        icon: "audit-outlined",
      },
    },
    {
      name: "finance-apply",
      path: "finance-apply-:companyId",
      component: () => import("../views/loan/FinanceApply.vue"),
      meta: {
        perms: authEntity.ENTERPRISE_FINANCE_APPLY,
        type: "F",
        title: "融资申请",
        icon: "audit-outlined",
      },
    },
    {
      name: "channels",
      path: "channels",
      component: () => import("../views/channel/index.vue"),
      meta: {
        perms: authEntity.CHANNEL_LIST,
        type: "M",
        title: "渠道管理",
        icon: "deployment-unit-outlined",
      },
    },
    {
      name: "channel-add",
      path: "channel-add",
      component: () => import("../views/channel/ChannelAdd.vue"),
      meta: {
        perms: authEntity.CHANNEL_ADD,
        type: "F",
        title: "新增渠道",
        icon: "deployment-unit-outlined",
      },
    },
    {
      name: "channel-edit",
      path: "channel-edit-:sourcesId",
      component: () => import("../views/channel/ChannelEdit.vue"),
      meta: {
        perms: authEntity.CHANNEL_EDIT,
        type: "F",
        title: "编辑渠道",
        icon: "deployment-unit-outlined",
      },
    },
    {
      name: "regist-record",
      path: "regist-record-:sourcesId",
      component: () => import("../views/channel/RegistRecord.vue"),
      meta: {
        perms: authEntity.CHANNEL_REGISTER_LIST,
        type: "F",
        title: "注册记录",
        icon: "deployment-unit-outlined",
      },
    },
    {
      name: "banks",
      path: "banks",
      component: () => import("../views/bank/index.vue"),
      meta: {
        perms: authEntity.BANK_LIST,
        type: "M",
        title: "银行管理",
        icon: "bank-outlined",
      },
    },
    {
      name: "bank-add",
      path: "bank-add",
      component: () => import("../views/bank/BankAdd.vue"),
      meta: {
        perms: authEntity.BANK_ADD,
        type: "F",
        title: "新增",
        icon: "bank-outlined",
      },
    },
    {
      name: "bank-edit",
      path: "bank-edit-:bankId",
      component: () => import("../views/bank/BankEdit.vue"),
      meta: {
        perms: authEntity.BANK_EDIT,
        type: "F",
        title: "编辑",
        icon: "bank-outlined",
      },
    },
    {
      name: "branch-manage",
      path: "branch-manage",
      component: () => import("../views/bank/BranchManage.vue"),
      meta: {
        perms: authEntity.BANK_SUB_BRANCH,
        type: "F",
        title: "支行管理",
        icon: "bank-outlined",
      },
    },
    {
      name: "contact-list",
      path: "contact-list",
      component: () => import("../views/bank/ContactList.vue"),
      meta: {
        perms: authEntity.BANK_CONTACT,
        type: "F",
        title: "通讯录",
        icon: "bank-outlined",
      },
    },
    {
      name: "customer-manage",
      path: "customer-manage",
      component: () => import("../views/bank/CustomerManage.vue"),
      meta: {
        perms: authEntity.BANK_MANAGER,
        type: "F",
        title: "客户经理管理",
        icon: "bank-outlined",
      },
    },
    {
      name: "customer-add",
      path: "customer-add-:bankId",
      component: () => import("../views/bank/CustomerAdd.vue"),
      meta: {
        perms: authEntity.BANK_MANAGER_ADD,
        type: "F",
        title: "新增客户经理",
        icon: "bank-outlined",
      },
    },
    {
      name: "customer-edit",
      path: "customer-edit",
      component: () => import("../views/bank/CustomerEdit.vue"),
      meta: {
        perms: authEntity.BANK_MANAGER_EDIT,
        type: "F",
        title: "编辑经理管理",
        icon: "bank-outlined",
      },
    },
    {
      name: "loan-review",
      path: "loan-review",
      component: () => import("../views/loan/audit/index.vue"),
      meta: {
        perms: authEntity.LOAN_REVIEW,
        type: "M",
        title: "贷款审核",
        icon: "security-scan-outlined",
      },
    },
    {
      name: "loan-progress",
      path: "loan-progress",
      component: () => import("../views/loan/advance/index.vue"),
      meta: {
        perms: authEntity.LOAN_PROGRESS,
        type: "M",
        title: "授信进度",
        icon: "project-outlined",
      },
    },
    {
      name: "files",
      path: "files",
      component: () => import("../views/data/index.vue"),
      meta: {
        perms: authEntity.FILE_LIST,
        type: "M",
        title: "资料管理",
        icon: "folder-open-outlined",
      },
    },
    {
      name: "files-add",
      path: "files-add-:bankId",
      component: () => import("../views/data/DataAdd.vue"),
      meta: {
        perms: authEntity.FILE_EDIT,
        type: "F",
        title: "新增资料",
        icon: "folder-open-outlined",
      },
    },
    {
      name: "users",
      path: "users",
      component: () => import("../views/user/UserManage.vue"),
      meta: {
        perms: authEntity.USER_LIST,
        type: "M",
        title: "用户管理",
        icon: "UserOutlined",
      },
    },
    {
      name: "user-add",
      path: "user-add",
      component: () => import("../views/user/UserAdd.vue"),
      meta: { perms: authEntity.USER_ADD },
    },
    {
      name: "user-edit",
      path: "user-edit-:userId",
      component: () => import("../views/user/UserEdit.vue"),
      meta: { perms: authEntity.USER_EDIT },
    },
    {
      name: "password-modify",
      path: "password-modify",
      component: () => import("../views/user/PasswordModify.vue"),
    },
    {
      name: "user-regist-record",
      path: "user-regist-record",
      component: () => import("../views/user/RegistRecord.vue"),
    },
    {
      name: "roles",
      path: "roles",
      component: () => import("../views/auth/RoleManage.vue"),
      meta: {
        perms: authEntity.ROLE_LIST,
        type: "M",
        title: "角色管理",
        icon: "team-outlined",
      },
    },
    {
      name: "role-add",
      path: "role-add",
      component: () => import("../views/auth/RoleAdd.vue"),
      meta: { perms: authEntity.ROLE_ADD },
    },
    {
      name: "role-edit",
      path: "role-edit-:roleId",
      component: () => import("../views/auth/RoleEdit.vue"),
      meta: { perms: authEntity.ROLE_EDIT },
    },
    {
      name: "config-manage",
      path: "config-manage",
      component: () => import("../views/loan/LoanStatus.vue"),
      meta: {
        perms: authEntity.CONFIG_LIST,
        title: "配置管理",
        type: "M",
        icon: "setting-outlined",
      },
    },
  ],
};

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (whiteList.includes(to.fullPath)) {
    next();
  } else if (getToken()) {
    if (!store.state.user) {
      await store.dispatch("getUserInfo");
      const { permissions } = store.state;

      const dynamicRouters = {
        ...mainRouters,
        children: mainRouters.children.filter(
          (item) =>
            permissions.includes("*:*:*") ||
            !item.meta?.perms ||
            permissions.includes(item.meta?.perms)
        ),
      };
      router.addRoute(dynamicRouters);
      next({ ...to, replace: true });
    } else {
      next();
    }
  } else {
    next("/login");
  }
});

export default router;

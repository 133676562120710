export default {

  // 企业管理相关权限码
  ENTERPRISE_LIST: 'enterprise:list', // 查询列表
  ENTERPRISE_ADD: 'enterprise:add', // 新增企业信息
  ENTERPRISE_EDIT: 'enterprise:edit', // 编辑企业信息
  ENTERPRISE_DELETE: 'enterprise:delete', // 删除企业信息
  ENTERPRISE_DETAIL: 'enterprise:detail', // 查看企业详情
  ENTERPRISE_FINANCIAL: 'enterprise:financial', // 查看企业财务情况
  ENTERPRISE_FINANCIAL_EDIT: 'enterprise:financial-edit', // 编辑企业财务情况
  ENTERPRISE_PROGRESS: 'enterprise:follow-up', // 企业跟进记录
  ENTERPRISE_PROGRESS_EDIT: 'enterprise:follow-up-edit', // 新增企业跟进记录
  ENTERPRISE_FINANCE_APPLY: 'enterprise:finance-apply', // 企业融资申请
  ENTERPRISE_FINANCE_APPLY_AUDIT: 'enterprise:finance-apply-audit', // 融资申请审核状态
  ENTERPRISE_FINANCE_APPLY_COMMIT: 'enterprise:finance-apply-commit', // 提交融资申请

  ENTERPRISE_AUDIT_LIST: 'enterprise:audit-list', // 企业审核查看
  ENTERPRISE_AUDIT: 'enterprise:audit', // 企业审核

  // 渠道管理相关权限码
  CHANNEL_LIST: 'channel:list', // 查询渠道列表
  CHANNEL_ADD: 'channel:add', // 新增渠道信息
  CHANNEL_EDIT: 'channel:edit', // 编辑渠道信息
  CHANNEL_REGISTER_LIST: 'channel:register-list', // 查询渠道注册记录
  CHANNEL_PASSWORD_RESET: 'channel:password-reset', // 重置渠道密码
  CHANNEL_DELETE: 'channel:delete', // 删除渠道


  // 银行管理相关权限码
  BANK_LIST: 'bank:list', // 查询银行列表
  BANK_ADD: 'bank:add', // 新增银行信息
  BANK_EDIT: 'bank:edit', // 编辑银行信息
  BANK_DELETE: 'bank:delete', // 编辑银行信息
  BANK_SUB_BRANCH: 'bank:sub-branch', // 支行管理
  BANK_SUB_BRANCH_ADD: 'bank:sub-branch-add', // 新增支行
  BANK_SUB_BRANCH_EDIT: 'bank:sub-branch-edit', // 编辑支行
  BANK_SUB_BRANCH_DELETE: 'bank:sub-branch-delete', // 编辑支行
  BANK_CONTACT: 'bank:contact', // 通讯录查询
  BANK_CONTACT_ADD: 'bank:contact-add', // 新增通讯录
  BANK_CONTACT_EDIT: 'bank:contact-edit', // 通讯录编辑
  BANK_CONTACT_DELETE: 'bank:contact-delete', // 通讯录删除
  BANK_MANAGER: 'bank:manager', // 客户经理查询
  BANK_MANAGER_ADD: 'bank:manager-add', // 新增客户经理
  BANK_MANAGER_EDIT: 'bank:manager-edit', // 编辑客户经理
  BANK_MANAGER_DELETE: 'bank:manager-delete', // 银行客户经理删除
  BANK_MANAGER_DISABLED: 'bank:manager-disabled', // 银行客户经理禁用


  // 贷款审核权限码
  LOAN_REVIEW: 'loan:review', // 贷款审核查询
  LOAN_REVIEW_AUDIT: 'loan:review-audit', // 贷款审核查询

  // 授信进度
  LOAN_PROGRESS: 'loan:progress', // 授信进度查询


  // 资料管理相关权限码
  FILE_LIST: 'file:list', // 查询资料
  FILE_DETAIL: 'file:detail', // 查询资料
  FILE_ADD: 'file:add', // 新增资料
  FILE_EDIT: 'file:edit', // 编辑资料
  FILE_DELETE: 'file:delete', // 删除资料

  // 用户管理相关权限码
  USER_LIST: 'user:list', // 用户列表查询
  USER_ADD: 'user:add', // 新增用户信息
  USER_EDIT: 'user:edit', // 编辑用户信息
  USER_DELETE: 'user:delete', // 删除用户信息
  USER_DISABLED: 'user:disabled', // 禁用用户
  USER_PASSWORD_RESET: 'user:password-reset', // 重置用户密码
  
  // 角色管理相关权限码
  ROLE_LIST: 'role:list', // 角色列查询
  ROLE_ADD: 'role:新增', // 新增角色信息
  ROLE_EDIT: 'role:edit', // 修改角色信息
  ROLE_DELETE: 'role:delete', // 删除角色信息

  // 配置管理相关权限码
  CONFIG_LIST: 'config:list', // 配置信息查询
  CONFIG_EDIT: 'config:edit', // 编辑配置信息

}
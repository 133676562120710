import { ApiResponse } from '@/type/base';
import { LoginForm, LoginData, UserList, User, modifyUserForm } from '@/type/user'
import http from '@/utils/http'

// 登录方法
export function login(params: LoginForm) {
  return http.post<LoginData>('/api/system/login/login', params); 
}

export function logout() {
  return http.delete('/api/system/user/logout');
}

export async function getSystemUsers(params: User): Promise<UserList> {
  const { data } =  await http.get('/api/system/user/list', { params: { ...params, pageSize: 20000, pageNum: 1} });
  return {
    total: data.total,
    rows: data.rows.map((item: any) => ({
      ...item,
      orgName: (item.dept || {}).deptName
    }))
  };
}

export function addSystemUser(params: User) {
  return http.post('/api/system/user/add', params);
}

export function updateSystemUser(params: User) {
  return http.put('/api/system/user/edit', params);
}

export function toggleSystemUserStatus(userId: number, status: string) {
  return http.put('/api/system/user/changeStatus', { userId, status: status === '1' ? '0' : '1' });
}

export function modifyUserPassword(params: modifyUserForm) {
  return http.put('/api/system/user/profile/updatePwd', params);
}

export function modifyUserPasswordBySms(params: modifyUserForm) {
  return http.post('/api/system/user/profile/updatePwdByCode', params);
}

export function getSystemUser(userId: string) {
  return http.get(`/api/system/user/${userId}`);
}

export function deleteSystemUser(userId: string) {
  return http.delete(`/api/system/user/${userId}`);
}

export function getUserInfo() {
  return http.get('/api/system/login/getInfo');
}

export function getSystemDeptList() {
  return http.get('/api/system/dept/list');
}

export function verifyChannelCode(referralCode: string) {
  return http.get('/api/system/user/checkReferralCode', { params: { referralCode } });
}

export function sendSmsCode(mobile: string) {
  return http.get(`/api/common/sendSmsCode/${mobile}`)
}
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { Button, ButtonProps } from "ant-design-vue";

interface AuthButtonProps extends ButtonProps{
  permission?: string;
}

export default defineComponent<AuthButtonProps>({
  setup(props, { attrs, slots }) {
    const store = useStore();
    const isPermission = store.getters.isPermission(attrs.permission);
    return () => {
        if (isPermission) {
          return <Button { ...attrs } { ...props } v-slots={{ ...slots }}/>
        }
      }
    }
});
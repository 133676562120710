import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BankOutlined,
  SettingOutlined,
  TeamOutlined,
  FolderOpenOutlined,
  SolutionOutlined,
  AuditOutlined,
  SecurityScanOutlined,
  ProjectOutlined,
  DeploymentUnitOutlined,
  ShopOutlined,
  DownOutlined,
  FormOutlined,
  LogoutOutlined 
} from '@ant-design/icons-vue'
import { App } from 'vue';

export function registerMenuIcon(app: App) {
  app.component('UserOutlined', UserOutlined);
  app.component('VideoCameraOutlined', VideoCameraOutlined);
  app.component('UploadOutlined', UploadOutlined);
  app.component('MenuUnfoldOutlined', MenuUnfoldOutlined);
  app.component('BankOutlined', BankOutlined);
  app.component('MenuFoldOutlined', MenuFoldOutlined);
  app.component('SettingOutlined', SettingOutlined);
  app.component('TeamOutlined', TeamOutlined);
  app.component('FolderOpenOutlined', FolderOpenOutlined);
  app.component('SolutionOutlined', SolutionOutlined);
  app.component('AuditOutlined', AuditOutlined);
  app.component('SecurityScanOutlined', SecurityScanOutlined);
  app.component('ProjectOutlined', ProjectOutlined);
  app.component('DeploymentUnitOutlined', DeploymentUnitOutlined);
  app.component('ShopOutlined', ShopOutlined);
  app.component('DownOutlined', DownOutlined);
  app.component('FormOutlined', FormOutlined);
  app.component('LogoutOutlined', LogoutOutlined);
}
import http from "@/utils/http";
import { LoanStatusForm } from "@/type/loan";

// 获取贷款状态字典
export function getLoanStatus(dictType: string) {
  return http.get(`/api/system/dict/data/type/${dictType}`)
}

// 新增贷款状态字典
export function addLoanStatu(form: LoanStatusForm) {
  return http.post('/api/system/dict/data', form)
}

// 修改贷款状态字典
export function updateLoanStatu(form: LoanStatusForm) {
  return http.put('/api/system/dict/data', form)
}

// 删除贷款状态字典
export function delLoanStatu(dictCodes: string | number) {
  return http.delete(`/api/system/dict/data/${dictCodes}`);
}

// 贷款审核列表
export function queryLoanAuditList(params: object) {
  return http.get('/api/crm/loan/list', { params: { ...params, pageSize: 20000, pageNum: 1} })
}

// 修改审核状态
export function updateAuditStatus(form: object) {
  return http.put('/api/crm/loan', form)
}

// 新增贷款
export function addLoan(form: object) {
  return http.post('/api/crm/loan', form)
}
 
// 新增审核 
export function addAudit(form: object) {
  return http.post('/api/crm/audit', form)
}

// 智能推荐
export function queryRecommendList(params: object) {
  return http.get('/api/system/company/intelligentList', { params })
}

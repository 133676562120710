import axios, { AxiosRequestConfig } from 'axios';
import { message as Message, Modal, notification } from 'ant-design-vue';
import { getToken, removeToken } from '@/utils/auth';
import { saveAs } from 'file-saver';
import { ERROR_CODE } from '@/type/constant';
import router from '@/router';

// 是否显示重新登录
export let isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = ERROR_CODE[code] || res.data.msg || ERROR_CODE['default']
    // 二进制数据则直接返回
    if(res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer'){
      return res.data
    }
    if (code === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        Modal.confirm({
          title: '系统提示',
          content: '登录状态已过期，您可以继续留在该页面，或者重新登录',
          okText: '重新登录',
          cancelText: '取消',
          type: 'warning',
          onOk() {
            removeToken();
            router.push('/login');
          }
        })
    }
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    }
    if (code === 500) {
      if (!msg.includes('已存在')) {
        Message.error(msg)
      }
      return Promise.reject(new Error(msg))
    }
    if (code === 601) {
      Message.warning(msg)
      return Promise.reject('error')
    }
    if (code !== 200) {
      notification.error({ message: msg })
      return Promise.reject('error')
    }
    return res;
  },
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    } 
    Message.error(message, 5000 )
    return Promise.reject(error)
  }
)

export async function blobValidate(data: any) {
  try {
    const text = await data.text();
    JSON.parse(text);
    return false;
  } catch (error) {
    return true;
  }
}

// 通用下载方法
export function download(url?: string, filename?: string, config?: AxiosRequestConfig) {
  if (!url || !filename) {
    return;
  }
  return service.get(url, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...(config || {})
  }).then(async (data) => {
    const isBlob = await blobValidate(data);
    if (isBlob) {
      const blob = new Blob([data as any])
      saveAs(blob, filename)
    }
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
  })
}

export default service

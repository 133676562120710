export const ERROR_CODE: {[x: string]: string} = {
  401: '认证失败，无法访问系统资源',
  403: '当前操作没有权限',
  404: '访问资源不存在',
  default: '系统未知错误，请反馈给管理员'
}

export const USER_STATUS = {
  '1': '正常',
  '0': '禁用'
}

export const MENUS = [
  {
    menuId: 1,
    menuName: '企业管理',
    component: '@/views/enterprise/EnterpriseManage.vue',
    parentId: 0,
    orderNum: 1,
    path: 'enterprise',
    icon: ''
  },
  {
    menuId: 2,
    menuName: '审核贷款',
    component: '',
    parentId: 1,
    orderNum: 2,
    path: 'enterprise',
    icon: ''
  },
  {
    menuId: 3,
    menuName: '贷款情况',
    component: '',
    parentId: 1,
    orderNum: 3,
    path: 'enterprise',
    icon: ''
  },
  {
    menuId: 4,
    menuName: '企业审核',
    component: '',
    parentId: 1,
    orderNum: 4,
    path: 'enterprise',
    icon: ''
  },
  {
    menuId: 5,
    menuName: '申请贷款',
    component: '',
    parentId: 1,
    orderNum: 5,
    path: 'enterprise',
    icon: ''
  },
  {
    menuId: 6,
    menuName: '企业管理',
    component: '',
    parentId: 1,
    orderNum: 6,
    path: 'enterprise',
    icon: ''
  }
]
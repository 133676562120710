import { createApp } from 'vue'
import Antd, { message } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import '@/styles/global.less'
import App from './App.vue'
import router from './router'
import store from './store'
import { registerMenuIcon } from './layout/menuIcon';
import AuthButton from './views/common/AuthButton';

const app = createApp(App);
app.use(store)
.use(router)
.use(Antd)
.mount('#app');

app.component('a-button', AuthButton);

// 吐司全局配置
message.config({
  duration: 2,
});

registerMenuIcon(app);

app.config.errorHandler = (err, instance, info) => {
  // 处理错误，例如：报告给一个服务
  console.log(err);
}

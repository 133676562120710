import { getRoleMenus, getSystemMenus, getUserRouters } from '@/api/auth';
import { getUserInfo, login, logout } from '@/api/user';
import router from '@/router';
import { LoginForm, User } from '@/type/user';
import { removeToken, setToken } from '@/utils/auth';
import { createStore } from 'vuex';
import { getSystemRoles } from '@/api/auth'
import { Role } from '@/type/auth';
import { getLoanStatus } from "@/api/loan";

export default createStore<{ user: User | null, permissions: string[], roleList: Array<Role>, userRules: Array<Role>,loanStatus: Array<object>}>({
  state: {
    user: null,
    permissions: [],
    roleList: [],
    userRules: [],
    loanStatus: [], // 贷款状态
  },
  getters: {
    isPermission: ({ permissions }) => {
      return (authEntity: string) => !authEntity || permissions.includes('*:*:*')
      || permissions.includes(authEntity)
    }
  },
  mutations: {
    UPDATE_USER(state, user) {
      state.user = user;
    },
    UPDATE_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    SET_ROLE_LIST(state, roleList) {
      state.roleList = roleList || [];
    },
    UPDATE_USER_RULES(state, userRoles) {
      state.userRules = userRoles || []
    },
    SET_LOAN_STATUS_LIST(state, loanStatus) {
      state.loanStatus = loanStatus || [];
    },
  },
  actions: {
    async loginAction({ dispatch }, params: LoginForm) {
      const { data: { token } } = await login(params);
      setToken(token);
      router.push('/');
    },
    async logoutAction({ commit }) {
      await logout();
      removeToken();
      commit('UPDATE_USER', null);
      commit('UPDATE_PERMISSIONS', []);
      router.push('/login');
    },
    async getUserInfo({ commit, dispatch }) {
      const { data: { user, roles, permissions } } = await getUserInfo();
      const { data: { rows: roleData } } = await getSystemRoles();

      console.log(roles, roleData);
      let userRules = [];
      if (roles.some((r: string) => r === 'channel_manager')) {
        userRules = roleData.filter(r => r.roleKey === 'channel_salesman');
      } else {
        userRules = roleData;
      }
      commit('UPDATE_USER_RULES', userRules);

      commit('UPDATE_USER', user);
      commit('UPDATE_PERMISSIONS', permissions);
    },
    // 字典 - 角色列表
    async getRoleList()  {
      const { data: { rows } } = await getSystemRoles();
      this.commit('SET_ROLE_LIST', rows);
    },
    // 字典 - 贷款审核状态
    async getLoanStatus()  {
      const { data } = await getLoanStatus('sys_loan_status');
      this.commit('SET_LOAN_STATUS_LIST', data?.data);
    },
  }
})

import { Menu, Role, RoleList } from "@/type/auth";
import http from "@/utils/http";

export function getSystemRoles() {
  return http.get<RoleList>('/api/system/role/list?pageNum=1&pageSize=10000')
}

export function deleteSystemRole(roleId: number) {
  return http.delete(`/api/system/role/${roleId}`);
}

export function getSystemMenus() {
  return http.get<{ data: Menu[] }>('/api/system/menu/list');
}

export function getSystemRole(roleId: string) {
  return http.get(`/api/system/role/${roleId}`)
}

export function addSystemRole(role: Role) {
  return http.post('/api/system/role', role);
}

export function updateSystemRole(role: Role) {
  return http.put('/api/system/role', role);
}

export function getRoleMenus(roleId: number|string) {
  return http.get(`/api/system/menu/roleMenuTreeselect/${roleId}`);
}

export function getUserRouters() {
  return http.get('/api/system/login/getRouters');
}